










import { Component, Vue, Prop } from 'vue-property-decorator';
import Percent from '@/modules/common/types/percent.type';
import PercentFilter from '@/modules/common/filters/percent.filter';
import Occupancy from './occupancy.vue';

@Component({
    filters: { PercentFilter },
    components: { Occupancy },
})
export default class OccupancyExtended extends Vue {
    @Prop({
        type: Number,
        default: 0,
    })
    valuePercent!: Percent;
}
