







import { Component, Prop, Vue } from 'vue-property-decorator';
import FleetPopup from '@/modules/cars/modules/fleet/components/fleet-popup.vue';

@Component({
    components: { FleetPopup },
})
export default class DayRateModalPage extends Vue {
    @Prop({
        type: String,
    })
    private source?: string;
}
